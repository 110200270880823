import type { CreateVersionType } from './mock';

/**
 * Returns an empty `Version` object that's used when creating a new version.
 */
export function createDefaultVersion(keys: Partial<CreateVersionType>): CreateVersionType {
  return {
    base: '',
    name: '',
    release_stage: 'release',
    state: 'current',
    visibility: 'hidden',
    ...keys,
  };
}
