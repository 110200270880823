import type { Instance } from 'tippy.js';

import React, { useRef } from 'react';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import LazyTippy from '@ui/LazyTippy';

import CreateVersionForm from './Form';
import CreateVersionFormProvider, { useCreateVersionFormContext } from './FormProvider';

function Content() {
  const formValue = useCreateVersionFormContext();
  const { formState } = formValue;
  const tippyRef = useRef<Instance>();

  return (
    <LazyTippy
      appendTo={document.body}
      arrow={false}
      content={<CreateVersionForm onSuccess={() => tippyRef.current?.hide()} />}
      hideOnClick={!formState.isSubmitting}
      interactive
      maxWidth={320}
      offset={[0, 10]}
      onMount={instance => {
        tippyRef.current = instance;
        formValue.reset();
      }}
      placement="top-end"
      role="dialog"
      trigger="click"
      zIndex={10002} // Must be less than our Modal z-index.
    >
      <Button aria-label="Create new version" kind="secondary" outline size="xs">
        <Icon aria-hidden="true" name="plus" />
        New Version
      </Button>
    </LazyTippy>
  );
}

/**
 * Renders the CTA to create a new version that opens up a form inside a
 * popover. When creation is successful, popover is hidden. If an error occurs,
 * popover remains open so the form can be resubmitted if needed.
 */
export default function CreateVersion() {
  return (
    <CreateVersionFormProvider>
      <Content />
    </CreateVersionFormProvider>
  );
}
