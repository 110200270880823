import React from 'react';
import { useHistory } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import type { InitializeSuperHubVersionsProps } from '@core/store';
import { InitializeSuperHubVersions } from '@core/store';

import BasePanel from '@routes/SuperHub/components/BasePanel';

import Button from '@ui/Button';
import Icon from '@ui/Icon';

import CreateVersion from './CreateVersion';
import styles from './index.module.scss';
import VersionsList from './List';

interface VersionsPanelProps {
  initialVersions?: InitializeSuperHubVersionsProps['initialVersions'];
}

export default function VersionsPanel({ initialVersions }: VersionsPanelProps) {
  const bem = useClassy(styles, 'VersionsPanel');

  const history = useHistory();

  return (
    <InitializeSuperHubVersions initialVersions={initialVersions}>
      <BasePanel label="Manage versions">
        <div className={bem('&')}>
          <header className={bem('-header')}>
            <Button
              aria-label="Close versions panel"
              className={bem('-close-button')}
              kind="secondary"
              onClick={() => {
                history.push('/');
              }}
              outline
              size="xs"
            >
              <Icon className={bem('-close-button-icon')} name="arrow-left-square" />
            </Button>
          </header>

          <VersionsList />

          <footer className={bem('-footer')}>
            <CreateVersion />
          </footer>
        </div>
      </BasePanel>
    </InitializeSuperHubVersions>
  );
}
