import type { ListChildComponentProps } from 'react-window';

import React, { forwardRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { areEqual, VariableSizeList } from 'react-window';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';
import type { ReadVersionCollectionType } from '@core/store/SuperHub/Versions/mock';

import styles from './index.module.scss';
import VersionsListItem from './Item';

const defaultItemSize = 27;

const List = forwardRef<HTMLUListElement>(function List({ ...rest }, ref) {
  const bem = useClassy(styles, 'VersionsList');

  return <ul ref={ref} {...rest} className={bem('&')} />;
});

const Item = React.memo(function Item({
  data,
  index,
  style,
}: ListChildComponentProps<ReadVersionCollectionType['data']>) {
  return <VersionsListItem style={style} version={data[index]} />;
}, areEqual);

export default function VersionsList() {
  const versions = useSuperHubStore(s => s.versions.data.data);

  const itemCount = versions.length;

  return (
    <div>
      <AutoSizer defaultHeight={itemCount * defaultItemSize}>
        {autoSizerProps => (
          <VariableSizeList
            estimatedItemSize={defaultItemSize}
            innerElementType={List}
            itemCount={itemCount}
            itemData={versions}
            itemKey={(index, data) => {
              return data[index].name;
            }}
            itemSize={() => defaultItemSize}
            {...autoSizerProps}
          >
            {Item}
          </VariableSizeList>
        )}
      </AutoSizer>
    </div>
  );
}
