import React, { useEffect } from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';
import { useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Notification, { notify } from '@ui/Notification';
import { RHFGroup } from '@ui/RHF';
import Select from '@ui/Select';

import styles from './Form.module.scss';
import { useCreateVersionFormContext } from './FormProvider';
import CreateVersionProgressModal from './ProgressModal';

function ErrorNotification({ name }: { name: string }) {
  const bem = useClassy(styles, 'CreateVersionForm');

  return (
    <Notification
      description={
        <div className={bem('-toast')}>
          Failed to create v{name}. Please try again or{' '}
          <a href="mailto:support@readme.io" rel="noreferrer" target="_blank">
            reach out to support
          </a>
          .
        </div>
      }
      kind="error"
      theme="dark"
      title="Problem Creating Version"
    ></Notification>
  );
}

function SuccessNotification({ name }: { name: string }) {
  const bem = useClassy(styles, 'CreateVersionForm');
  const [routeSection, slug] = useSuperHubStore(s => [s.routeSection, s.slug]);

  return (
    <Notification
      description={
        <div className={bem('-toast')}>
          v{name} has been created.{' '}
          <a
            // TODO: replace with `useBuildVersionPath()` after https://github.com/readmeio/readme/pull/13883 gets merged in.
            // https://linear.app/readme-io/issue/RM-11941/fork-version-update-view-now-version-link
            href={`/v${name}/${routeSection}/${slug}`}
            rel="noreferrer"
          >
            View now
          </a>
        </div>
      }
      kind="success"
      theme="dark"
      title="Version Created"
    ></Notification>
  );
}

interface CreateVersionFormProps {
  /** Called when new version is successfully created. */
  onSuccess?: () => void;
}

/**
 * Renders the individual form fields for creating a version. Must be wrapped
 * inside a `CreateVersionForm` context to gain access to all the React Hook
 * Form methods and controls.
 */
export default function CreateVersionForm({ onSuccess }: CreateVersionFormProps) {
  const bem = useClassy(styles, 'CreateVersionForm');
  const uid = useUniqueId('CreateVersionForm');
  const [createVersion, versions] = useSuperHubStore(s => [s.versions.createVersion, s.versions.data.data]);
  const formValue = useCreateVersionFormContext();

  // On initial load, immediately focus on the name field.
  useEffect(() => {
    formValue.setFocus('name');
  }, [formValue]);

  const handleSubmit = formValue.handleSubmit(async data => {
    // Trigger a form field error if version name is not unique.
    if (versions.some(version => version.name === data.name)) {
      formValue.setError('name', {
        type: 'value',
        message: 'Version already exists.',
      });
      return;
    }

    try {
      const response = await createVersion(data);
      notify(<SuccessNotification name={response.data.name} />);
      onSuccess?.();
    } catch (error) {
      notify(<ErrorNotification name={data.name} />);
    }
  });

  return (
    <form autoComplete="off" data-color-mode="dark" onSubmit={handleSubmit}>
      <Flex align="stretch" className={bem()} gap="sm" layout="col">
        <div className={bem('-fields')}>
          <RHFGroup control={formValue.control} id={uid('base')} label="Forking From" name="base" required>
            {({ field }) => (
              <Select
                {...field}
                options={versions.map(version => ({
                  label: `v${version.name}`,
                  value: version.name,
                }))}
                size="sm"
              ></Select>
            )}
          </RHFGroup>
          <Icon color="color-text-minimum-icon" name="arrow-right" size="md" wrapperClassName={bem('-fields-arrow')} />
          <RHFGroup
            control={formValue.control}
            description="In semver format"
            id={uid('name')}
            isSemVer
            label="New Version"
            name="name"
            required
          >
            {({ field }) => (
              <Input
                {...field}
                autoComplete="off"
                placeholder="2.0"
                prefix="v"
                size="sm"
                wrapperClassName={bem('-fields-name-wrapper')}
              />
            )}
          </RHFGroup>
        </div>

        <hr className={bem('-divider')} />

        <Flex align="stretch" className={bem('-cta')} gap="sm" layout="col">
          <Button fullWidth loading={!!formValue.formState.isSubmitting && 'Create Version'} size="sm" type="submit">
            Create Version
          </Button>
          <div className={bem('-cta-description')}>New versions will be hidden by default.</div>
        </Flex>
      </Flex>
      <CreateVersionProgressModal isOpen={formValue.formState.isSubmitting} />
    </form>
  );
}
