import React, { useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { useSuperHubStore } from '@core/store';
import { createDefaultVersion } from '@core/store/SuperHub/Versions/defaults';
import type { CreateVersionType } from '@core/store/SuperHub/Versions/mock';

interface CreateVersionFormProviderProps {
  children: React.ReactNode;
}

/**
 * Creates a new React Hook Form that loads the initial state for a new version.
 * Renders the form context provider that grants API access to all descendants.
 */
export default function CreateVersionFormProvider({ children }: CreateVersionFormProviderProps) {
  const [currentVersion, versions] = useSuperHubStore(s => [s.versions.currentVersion, s.versions.data.data]);

  const defaultVersion = useMemo(() => {
    /** Use first version as the fallback if no current version exists. */
    const fallbackVersion = versions.length ? versions[0].name : '';
    return createDefaultVersion({ base: currentVersion || fallbackVersion });
  }, [currentVersion, versions]);

  const formValue = useForm<CreateVersionType>({ defaultValues: defaultVersion });

  return <FormProvider {...formValue}>{children}</FormProvider>;
}

/**
 * Returns the React Hook Form context for our `CreateVersion` form.
 * @link https://react-hook-form.com/docs/useformcontext
 */
export const useCreateVersionFormContext = () => {
  const value = useFormContext<CreateVersionType>();
  if (!value) throw new Error('FormProvider<CreateVersionType> is not provided');
  return value;
};
