import { useCallback, useContext } from 'react';
import { matchPath } from 'react-router-dom';

import type { ProjectContextValue } from '@core/context';
import { LocalizationContext, ProjectContext } from '@core/context';
import { useSuperHubStore, useProjectStore } from '@core/store';

import { useSuperHubContext } from '@routes/SuperHub/components/Context';
import type { SuperHubRouteParams } from '@routes/SuperHub/types';
import { SuperHubRoutePaths } from '@routes/SuperHub/types';

export default function useBuildVersionPath() {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const defaultVersion = project.stable.version;
  const isEnterpriseChild = useSuperHubStore(s => s.enterprise?.isChild);
  const subdomain = useProjectStore(s => s.data.subdomain);
  const language = useContext(LocalizationContext);

  const { browserRouterLocation } = useSuperHubContext();
  const { pathname, hash } = browserRouterLocation;
  const match = matchPath<SuperHubRouteParams>(pathname, Object.values(SuperHubRoutePaths));
  const { action: actionSegment, section: sectionSegment } = match?.params ?? {};

  return useCallback(
    (versionName: string) => {
      /**
       * Path construction logic here mirrors the Hub header's version dropdown
       * @see /react/src/Hub/Header/utils/hooks.js
       */
      const subdomainSegment = isEnterpriseChild && subdomain;
      const languageSegment = language !== 'en' && `lang-${language}`;
      const versionSegment = defaultVersion !== versionName && `v${versionName}`;
      const hashSegment = hash || '';

      return `/${[subdomainSegment, languageSegment, versionSegment, actionSegment, sectionSegment]
        .filter(Boolean)
        .join('/')}${hashSegment}`;
    },
    [isEnterpriseChild, subdomain, language, defaultVersion, actionSegment, sectionSegment, hash],
  );
}
