import { useContext, useMemo } from 'react';

import type { ProjectContextValue, VersionContextValue } from '@core/context';
import { ProjectContext, VersionContext } from '@core/context';

import useBuildVersionPath from '@routes/SuperHub/Versions/hooks/useBuildVersionPath';

/**
 * Returns the current active version and a list of all versions for the current project.
 * This is used to render the version dropdown in the SuperHub project navigation.
 */
export default function useVersions() {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { version: activeVersion } = useContext(VersionContext) as VersionContextValue;

  const buildVersionPath = useBuildVersionPath();

  const versions = useMemo(() => {
    // Using the project context version list for now, eventually this data may come from APIv2.
    return project.versions.map(v => {
      const isActive = activeVersion === v.version;

      // When switching versions, we return the user to the root of the section they are in.
      // E.g. if a user is at /docs/test-page, switching to v2 will take them to /v2/docs.
      const path = buildVersionPath(v.version);

      return { label: v.version, isActive, path, isStable: v.is_stable, isHidden: v.is_hidden };
    });
  }, [activeVersion, buildVersionPath, project.versions]);

  return { activeVersion, versions };
}
